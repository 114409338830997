import React, { Component } from "react";
import "../style/BoxStyle.scss";
import ButtonComponent from "../../button/components/ButtonComponent";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

class BoxComponent extends Component {
  // t('BUY NOW', 'BUY NOW')
  render() {
    const {
      image,
      title,
      link,
      buttonLabel,
      highlight,
      id,
      newClass,
      collection,
      makeEffectFilter,
      t,
    } = this.props;

    let content, button;

    if (typeof this.props.openLightbox !== "function") {
      content = (
        <Link to={`/product/${id}`}>
          <img src={image} alt={title} />
        </Link>
      );
      button = (
        <ButtonComponent type="link" action={link}>
          {" "}
          {buttonLabel
            ? t(buttonLabel, buttonLabel)
            : t("SEE MORE", "SEE MORE")}
        </ButtonComponent>
      );
    } else {
      content = (
        <img
          src={image}
          alt={title}
          onClick={() => this.props.openLightbox(this.props.id)}
        />
      );
      if (link) {
        button = (
          <ButtonComponent type="link" action={link}>
            {buttonLabel
              ? t(buttonLabel, buttonLabel)
              : t("SEE MORE", "SEE MORE")}
          </ButtonComponent>
        );
      }
    }

    let fullClass =
      (highlight ? "boxComponent highlightBox " : "boxComponent ") + newClass;

    let effect = "";
    if (collection !== undefined && makeEffectFilter !== undefined) {
      if (makeEffectFilter === collection) {
        effect = "effect";
      } else {
        effect = "";
      }
    } else {
      effect = "";
    }

    return (
      <div className={`${fullClass}collection_${collection} ${effect}`}>
        {content}
        {(title || button) && (
          <div className="titleBoxComponent">
            <span>{title}</span>
            {window.localStorage.getItem("user_type") === "buyer" && (
              <div className="buttonBox">{button}</div>
            )}
          </div>
        )}
      </div>
    );
  }
}
BoxComponent.propTypes = {
  openLightbox: PropTypes.func,
};

export default withTranslation()(BoxComponent);
