import React, { Component } from "react";
import ReactTooltip from "react-tooltip";
import "../style/ProductStyle.scss";
import { Link } from "react-router-dom";
import LoadingComponent from "../../loading/components/LoadingComponent";
import CartInterector from "../../../../domain/pages/cart/CartInterector";
import CartService from "../../../../data/pages/cart/CartService";
import { connect } from "react-redux";
import FeedBackMsgComponents from "../../feedbackmsg/components/FeedBackMsgComponents";
import {
  getCurrentPriceByPriceList,
  GetVariationColorStyle,
} from "../../../../utils/productHelper.js";
import store from "../../../../data/store";
import { save } from "../../../../data/pages/products/showNotificationItemAddedActions";
import { isMobile } from "is-mobile";
import { withTranslation } from "react-i18next";
import AddToCartComponents from "../../addtocart/components/AddToCartComponents";
import LightboxHalfScreenComponent from "../../lightboxHalfScreen/components/LightboxHalfScreenComponent";
import InfoProductService from "../../../../data/pages/inforproduct/InfoProductService";
import SpinnerComponent from "../../spinner";
import VariationComponent from "../../infoProduct/variation/component/VariationComponent";
import ReactImageMagnify from "react-image-magnify";

class ProductComponent extends Component {
  myOwnList = [];
  listProduct = [];

  constructor(props) {
    super(props);

    this.state = {
      infoProduct: [],
      currentItemVariation: 0,
      imagesVariations: [],
      currentVariation: [{id: 0, variation: 0}],
      currentItemVariation: 0,
      currentImage: '',
      showFeedback: false,
      msg: false,
      selectedProduct: "",
      overlay: false,
      isProductInCart: false,
      showVariationsModal: false,
      variations: [],
      selectedVariations: [],
      currentProduct: {},
      loadingVariations: false,
      loadingAddToCart: false,
      showErrorVariation: false,
    };

    this.timer = null;
    this.closeFeedback = this.closeFeedback.bind(this);
  }

  addToCart(variations = undefined) {
    if (this.state.selectedVariations.length < 1 && variations === undefined) {
      this.setState({
        showErrorVariation: true
      })
      return;
    }
    this.setState({
      overlay: true,
      loadingAddToCart: true,
    });
    new CartInterector(
      new CartService()
        .addVariationsToCart(
          variations ? variations : this.state.selectedVariations
        )
        .then((res) => {
          this.setState({
            loadingAddToCart: false,
            showVariationsModal: false,
            showErrorVariation: false,
            selectedVariations: [],
          });
          if (res === 409) {
            this.setState({
              isProductInCart: true,
              msg: false,
              selectedVariations: [],
              showErrorVariation: false,
            });
            this.showFeedback();
          } else {
            this.setState({
              msg: true,
              selectedProduct: this.state.currentProduct,
              selectedVariations: [],
              showErrorVariation: false,
            });
            this.showFeedback();
          }
        })
        .catch((reason) => {
          console.log(reason, 123);
        })
    );
  }

  handleShowVariarionsModal(product) {
    // Se não houver pricelist selecionada
    if(!window.localStorage.getItem('user.pricelist') || ["Null", "0", ""].includes(window.localStorage.getItem('user.pricelist'))){
      this.props.modalPricelist();
      this.props.pricelistCartCheck();
      return;
    }

    if (product.variations.length === 1) {
      this.addToCart([product.variations[0].id]);
    } else {
      this.setState({ currentProduct: product, loadingVariations: true });
      new InfoProductService().getVariations(product.id).then((res) => {
        this.setState({ variations: res, loadingVariations: false });
      });
      this.setState({ showVariationsModal: true });
    }
  }

  handleCheckboxVariation = (event) => {
    event.persist();
    if (event.target.checked) {
      if (!this.state.selectedVariations.includes(event.target.value)) {
        this.setState((prevState) => ({
          selectedVariations: [
            ...prevState.selectedVariations,
            event.target.value,
          ],
        }));
      }
    } else {
      this.setState((prevState) => ({
        selectedVariations: prevState.selectedVariations.filter(
          (variation) => variation !== event.target.value
        ),
      }));
    }
  };

  makeCreateMyOwnList(ev, idProductVariation) {
    if (!this.listProduct.includes(idProductVariation)) {
      this.listProduct.push(idProductVariation);
    }
    if (ev.target.checked) {
      this.myOwnList.push(parseInt(ev.target.value));
    } else {
      if (this.listProduct.includes(idProductVariation)) {
        this.listProduct = this.listProduct.filter(
          (id) => id !== parseInt(idProductVariation)
        );
      }
      this.myOwnList = this.myOwnList.filter(
        (id) => id !== parseInt(ev.target.value)
      );
    }
    this.props.makeCreateMyOwnList(this.myOwnList, this.listProduct);
  }
  showFeedback() {
    if (isMobile()) {
      this.setState({
        showFeedback: true,
      });
    } else if (this.state.isProductInCart) {
      store.dispatch(save("isProductInCart"));
    } else {
      store.dispatch(save(true));
    }
    clearTimeout(this.timer);
    this.timer = setTimeout(this.closeFeedback, 4000);
  }
  closeFeedback() {
    clearTimeout(this.timer);
    this.setState({
      showFeedback: false,
      msg: false,
      overlay: false,
      isProductInCart: false,
    });
    store.dispatch(save(false));
  }

  getProductVariations(id) {
    new InfoProductService().getVariations(id).then((res) => {
      this.setState({ variations: res })
    })
  }

  renderImagemProduto(product) {
    if (product.video) {
      return this.renderVideo(product.video);
    }
    if (product.video_url) {
      return this.renderYoutubeVideo(product.video_url);
    }

    let index

    if(this.state.currentVariation.length == 1) {
      index = 0

    } else {
      const isInCurrentVariation = this.state.currentVariation.filter(productFiltered => productFiltered.id == product.id)
      if(isInCurrentVariation) {
        index = isInCurrentVariation

      } else if(this.state.currentVariation.length > 2 && !isInCurrentVariation) {
        const isNotInCurrentVariation = this.state.currentVariation.filter(productFiltered => productFiltered.id != product.id)
        index = isNotInCurrentVariation

      } else {
        index = 0
      }
    }

    if(index != 0 && product.variations[index[0].variation].images.length > 0) {
      const src = product.variations[index[0].variation].images[this.state.currentItemVariation].picture_thumbnail
      return (
        <ReactImageMagnify
          {...{
            smallImage: {
              alt: product.name,
              isFluidWidth: true,
              src: src,
            },
            largeImage: {
              src: src,
              width: 1200,
              height: 1422,
            },
            enlargedImageContainerClassName: "enlargedImageContainer",
            enlargedImageClassName: "enlargedImage",
          }}
        />
      )
    } else {
      let src = "https://s3.amazonaws.com/blancfashion-api-media/product/product/product_placeholder.jpg"
      
      if (product.variations && product.variations[0] && product.variations[0].images) {
        if (product.variations[0].images[this.state.currentItemVariation] !== undefined) {
          src = product.variations[0].images[this.state.currentItemVariation].picture_thumbnail;
        } else if (product.variations[0].images.length > 0) {
          src = product.variations[0].images[0].picture_thumbnail;
        }
      }

      return (
        <ReactImageMagnify
          {...{
            smallImage: {
              alt: product.name,
              isFluidWidth: true,
              src: src,
            },
            largeImage: {
              src: src,
              width: 1200,
              height: 1422,
            },
            enlargedImageContainerClassName: "enlargedImageContainer",
            enlargedImageClassName: "enlargedImage",
          }}
        />
      );
    }
  }

  static whatIsTheVariationType(res, index) {
    const variationStyle = GetVariationColorStyle(res);

    return (
      <li
        key={index}
        data-tip={res.label}
        data-for={res.label}
        style={variationStyle.li}
      >
        <span
          data-tip={res.label}
          data-for={res.label}
          className="colorListNoTexture"
          style={variationStyle.span}
        ></span>
        <ReactTooltip
          className="variation-tooltip"
          id={res.label}
          place="top"
          type="light"
          effect="solid"
        />
      </li>
    );
  }

  selectVariation(id, key) {
    this.state.currentVariation.map((product) => {
      if(product.id != id) {
        this.setState(prevState => ({
          currentVariation: [...prevState.currentVariation, {id: id, variation: key}],
        }));
      } else {
        const removedPrevVariation = this.state.currentVariation.filter(product => product.id != id)

        this.setState({
          currentVariation: [...removedPrevVariation, {id: id, variation: key}],
        })
      }
    })
  }

  selectItemVariation(key) {
    this.setState({
      currentItemVariation: key,
    });
  }

  renderImg(product) {

    let variation

    if(this.state.currentVariation.length == 1) {
      variation = 0

    } else {
      const isInCurrentVariation = this.state.currentVariation.filter(productFiltered => productFiltered.id == product.id)
      if(isInCurrentVariation) {
        variation = isInCurrentVariation

      } else if(this.state.currentVariation.length > 2 && !isInCurrentVariation) {
        const isNotInCurrentVariation = this.state.currentVariation.filter(productFiltered => productFiltered.id != product.id)
        variation = isNotInCurrentVariation

      } else {
        variation = 0
      }
    }

    if(variation != 0) {
      if(product.variations[variation[0].variation].images.length >= 2) {
        return (
          <img
            className="hover-image"
            src={product.variations[variation[0].variation].images[1].picture_thumbnail}
            alt="Products"
          />
        )
      } else if (product.variations[variation[0].variation].images.length == 1) {
        return (
          <img
            className="hover-image"
            src={product.variations[variation[0].variation].images[0].picture_thumbnail}
            alt="Products"
          />
        )
      } else {
        return (
          <img
            className="hover-image"
            src={product.variations[0].images[0] ? product.variations[0].images[0].picture_thumbnail : 'https://s3.amazonaws.com/blancfashion-api-media/product/product/product_placeholder.jpg'}
            alt="Products"
          />
        )
      }
    } else {
      if (product.variations[0].images.length == 1) {
        return (
          <img
            className="hover-image"
            src={product.variations[0].images[0].picture_thumbnail}
            alt="Products"
          />
        )
      } else if(product.variations[0].images.length >= 2) {
        return (
          <img
            className="hover-image"
            src={product.variations[0].images[1].picture_thumbnail}
            alt="Products"
          />
        )
      }
    }
  }
  render() {
    const { products, t } = this.props;

    return (
      <div className="products">
        {this.state.showFeedback && this.state.msg && (
          <FeedBackMsgComponents close={this.closeFeedback}>
            {t("GREAT! THE ITEM", "GREAT! THE ITEM")}{" "}
            <span className="product-name">
              {" "}
              {this.state.selectedProduct.name}{" "}
            </span>{" "}
            {t(
              "HAS BEEN ADDED TO YOUR CART. CLICK",
              "HAS BEEN ADDED TO YOUR CART. CLICK"
            )}{" "}
            <span className="here">
              <Link
                to={{
                  pathname: "/cart",
                  state: { sellerId: this.state.selectedProduct.seller_id },
                }}
              >
                {t("HERE", "HERE")}
              </Link>
            </span>{" "}
            {t("TO CHECK IT OUT.", "TO CHECK IT OUT.")}
          </FeedBackMsgComponents>
        )}

        {this.state.showVariationsModal && (
          <LightboxHalfScreenComponent
            close={() => this.setState({ showVariationsModal: false, showErrorVariation: false })}
          >
            <h1
              style={{
                color: "#000",
                fontSize: "22px",
                margin: "0 0 20px",
                fontStyle: "normal",
              }}
            >
              VARIATIONS {this.state.currentProduct.name}
            </h1>
            <div style={{ marginTop: "50px" }}>
              <h2 style={{ color: "#50545f", fontSize: "16px" }}>
                select the desired variations
              </h2>
              <div className="contentVariation">
                <div className="itemContainer">
                  {!this.state.loadingVariations &&
                    this.state.variations.map((variation, index) => {
                      const variationStyle = GetVariationColorStyle(variation);
                      return (
                        <div className="item">
                          <label htmlFor={variation.id}>
                            <div
                              className={variationStyle.classList}
                              style={variationStyle.li}
                            >
                              <span
                                data-tip={variation.label}
                                data-for={variation.label}
                                className="colorListNoTexture"
                                style={variationStyle.span}
                              ></span>
                              <ReactTooltip
                                className="variation-tooltip"
                                id={variation.label}
                                place="top"
                                type="light"
                                effect="solid"
                              />
                            </div>
                          </label>
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id={variation.id}
                            value={variation.id}
                            onChange={(event) =>
                              this.handleCheckboxVariation(event)
                            }
                          />
                        </div>
                      );
                    })}
                </div>
              </div>
              {this.state.showErrorVariation ?
                <h3 style={{ color: "#e6291c", fontSize: "16px", textAlign: 'center' }}>
                  SELECT AT LEAST ONE VARIATION
                </h3> : <br />
              }
              {window.localStorage.getItem("user_type") === "buyer" && (
                <div className="btnAddToCart" onClick={() => this.addToCart()}>
                  <AddToCartComponents
                  // handleClick={this.addToCart.bind(this)}
                  // id={this.props.infoProductData[0].id}
                  >
                    {this.state.loadingAddToCart ? (
                      <SpinnerComponent />
                    ) : (
                      t("ADD TO CART", "ADD TO CART")
                    ) }
                  </AddToCartComponents>
                </div>
              )}
              <br />
            </div>
          </LightboxHalfScreenComponent>
        )}

        {this.state.overlay && <div className="overlayProduct" />}

        {this.state.showFeedback && this.state.isProductInCart && (
          <FeedBackMsgComponents
            msg={t(
              "THIS PRODUCT IS ALREADY IN YOUR CART.",
              "THIS PRODUCT IS ALREADY IN YOUR CART."
            )}
            close={this.closeFeedback}
          />
        )}

        {this.props.loading ? (
          <LoadingComponent />
        ) : (
          <div className="containerProducts">
              {products.map((product, index) => {
              return (
                <label
                  className="blocksProducts"
                  key={index}
                  htmlFor={`checkBox_${index}`}
                >
                  <div className="checkBoxProduct">
                    {this.props.isCheckBox && (
                      <input
                        type="checkbox"
                        value={product.variations[0].id}
                        onChange={(ev) => {
                          for (
                            let index = 0;
                            index < product.variations.length;
                            index++
                          ) {
                            const variation = product.variations[index];
                            this.makeCreateMyOwnList(ev, variation.id);
                          }
                        }}
                        id={`checkBox_${index}`}
                      />
                    )}
                  </div>
                  <div className="topBlockProducts">
                    {this.props.isCheckBox ? (
                      <>
                        {product.images.length > 0 && (
                          <div className="first-image-wrapper">
                            <img
                              className="first-image"
                              src={`${product.images[0].picture_thumbnail}`}
                              alt="Products"
                            />
                          </div>
                        )}
                        {product.images.length > 1 && (
                          <div className="hover-image-wrapper">
                            <img
                              className="hover-image"
                              src={`${product.images[1] ? product.images[1].picture_thumbnail : 'https://s3.amazonaws.com/blancfashion-api-media/product/product/product_placeholder.jpg'}`}
                              alt="Products"
                            />
                          </div>
                        )}
                      </>
                    ) : (
                      <Link to={`/product/${product.id}`}>
                        <>
                          {product.images.length > 0 ? (
                            <div className={`image product-${product.id}`}>
                              {
                                this.renderImagemProduto(product)
                              }
                            </div>
                          ) : (
                            <img
                                className="no-product-image"
                                src="https://s3.amazonaws.com/blancfashion-api-media/product/product/product_placeholder.jpg"
                                alt=""
                              />
                          )}

                          {product.images.length > 1 && (
                            <div className="hover-image-wrapper">
                              {
                                  this.renderImg(product)
                              }
                            </div>
                          )}
                        </>
                      </Link>
                    )}
                  </div>

                  <div className="mainProduct">
                    <div className="nameBrand">{product.seller}</div>

                    <p className="nameBrandCollection">{product.name}</p>

                    <VariationComponent
                      handleClick={this.selectVariation.bind(this, product.id)}
                      colors={product.variations}
                    />

                    <div className="prices">
                      <p>
                        <span
                          className="bold"
                          data-tip={product.wholesale_price_tip}
                          data-for={`wholesale_price_${product.id}`}
                        >
                          {t("W", "W")}&nbsp;
                        </span>
                        <ReactTooltip
                          className="variation-tooltip"
                          id={`wholesale_price_${product.id}`}
                          place="top"
                          type="light"
                          effect="solid"
                        />
                        {" | "}
                        {
                          getCurrentPriceByPriceList(product).wholesale_price
                            .currency
                        }{" "}
                        {parseFloat(
                          getCurrentPriceByPriceList(product).wholesale_price
                            .amount
                        ).toFixed(2)}
                      </p>
                      <p>
                        {" "}
                        <span
                          className="bold"
                          data-tip={product.retailer_price_tip}
                          data-for={`retailer_price_${product.id}`}
                        >
                          {t("R", "R")}&nbsp;
                        </span>
                        <ReactTooltip
                          className="variation-tooltip"
                          id={`retailer_price_${product.id}`}
                          place="top"
                          type="light"
                          effect="solid"
                        />
                        {" | "}
                        {
                          getCurrentPriceByPriceList(product).retailer_price
                            .currency
                        }{" "}
                        {parseFloat(
                          getCurrentPriceByPriceList(product).retailer_price
                            .amount
                        ).toFixed(2)}
                      </p>
                    </div>

                    {window.localStorage.getItem("user_type") === "buyer" && (
                      <button
                        onClick={() => product.is_out_of_stock ? null : this.handleShowVariarionsModal(product)}
                        className={`buttonProduct add-to-cart-btn ${product.is_out_of_stock && 'styled-block'}`}
                      >
                        { product.is_out_of_stock
                        ? t("OUT OF STOCK", "OUT OF STOCK")
                        : t("ADD TO CART", "ADD TO CART") }
                      </button>
                    )}
                  </div>
                </label>
              );
            })}
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    cartData: state.cartEntity,
  };
}

export default withTranslation()(connect(mapStateToProps)(ProductComponent));
