import axios from "axios";

class RdSendForm {
    async newsletter({mail, ctype, client_tracking_id, traffic_source}) {
        const tokenRdStation = localStorage.getItem("gtm_id_main")
        
        try {
            const res = await axios.post("https://www.rdstation.com.br/api/1.3/conversions", {
                token_rdstation: `${tokenRdStation}`,
                identificador: "newsletter",
                email: mail,
                cf_companytype: ctype,
                client_tracking_id: client_tracking_id,
                traffic_source: traffic_source,
            }, {
                headers: {
                    "Content-Type": "application/json",
                }
            })
        } catch (err) {
            return;
        }
    }
}

export default new RdSendForm();