import React from "react";
import Slider from "react-slick";
import "./style/SlickStyle.scss";

class SliderComponent extends React.Component {
  componentWillReceiveProps() {
    this.refs.slick.innerSlider.onWindowResized();
  }

  componentDidMount() {
    const imgs = document.getElementsByTagName("img");

    for (let index = 0; index < imgs.length; index++) {
      const element = imgs[index];
      if (element) {
        element.addEventListener("load", () =>
          this.refs.slick && this.refs.slick.innerSlider.onWindowResized()
        );
      }
    }
  }

  getSlidesPerRow() {
    if (!this.props.isMobile) {
      if (this.props.templateType === 3 || this.props.templateType === 2) {
        return 1;
      }
    }
    return this.props.slidesPerRow;
  }

  getSlidesToShow() {
    if (!this.props.isMobile) {
      if (this.props.templateType === 3 || this.props.templateType === 2) {
        return 2;
      }
    }
    return this.props.slidetoshow;
  }

  getSlidesToScroll() {
    if (!this.props.isMobile) {
      if (this.props.templateType === 3 || this.props.templateType === 2) {
        return 2;
      }
    }
    return this.props.slideScroll;
  }
  render() {
    const {
      children,
      dots,
      infinite,
      speed,
      isMobile,
      activeSlide,
      initialSlide,
      templateType,
      arrow,
    } = this.props;
    let templateTypeClass = templateType ? "template-" + templateType : "";
    let settings = {
      dots: dots,
      infinite: infinite,
      speed: speed,
      slidesToShow: this.getSlidesToShow(),
      slidesToScroll: this.getSlidesToScroll(),
      slidesPerRow: this.getSlidesPerRow(),
      initialSlide: initialSlide !== undefined ? initialSlide : 0,
      arrows: arrow || false,
    };

    if (!isMobile || activeSlide) {
      return (
        <Slider
          ref="slick"
          className={"slide " + templateTypeClass}
          {...settings}
        >
          {children}
        </Slider>
      );
    }

    return <div className="slide">{children}</div>;
  }
}

export default SliderComponent;
